import {
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { arrowBack, manageAccountIcon } from '../../assets/icons/icons';
import { ChargerStatusEventType } from '../../types/charger-status-event-type.enum';
import { useDomain } from '../../hooks/useDomain';
import { useAuth } from '../../hooks/useAuth';
import { useFetchChargerDetailQuery } from '../../services/chargers.api';
import { ROUTES } from '../../types/routes.enum';
import NetworkLogo from './network-logo.component';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  p3: {
    paddingTop: '1rem',
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: 'white',
  },
  p2: {
    paddingTop: '16px',
    paddingBottom: '16px',
    backgroundColor: 'white',
    paddingLeft: '12px',
  },
  link: {
    cursor: 'pointer',
    color: '#000',
  },
  header: {
    boxShadow: ' inset 0px -1px 0px #E5E8EB',
    backgroundColor: '#fff',
    position: 'sticky',
    top: '0px',
    maxWidth: '425px',
    zIndex: 1,
  },
  paddingTop: {
    paddingTop: '1%',
    textAlign: 'center',
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: '16px',
  },
  title: {
    color: '#202223',
    fontSize: '20px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '28px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textAlign: 'center',
  },
  alignCenter: {
    textAlign: 'center',
  },
  imageContainer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accountContainer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  accountIcon: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingRight: '0px !important',
  },
  editAction: {
    textAlign: 'right',
    paddingRight: '16px',
    color: '#18A0D7',
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
    },
    '& button': {
      color: '#18A0D7',
    },
  },
  right: {
    textAlign: 'right',
    paddingRight: '16px',
  },
  paddingLeft: {
    paddingLeft: '12px',
  },
}));

export const BackButtonHeader = ({
  title = '',
  subTitle = '',
  onBackArrowClicked = null, // if onBackArrowClicked is not null, we should display back arrow and execute onBackArrowClicked
  onIconClicked = null,
  onCloseClicked = null,
  icon = null,
  height = 60,
  companyIcon = null,
  isBackButtonRequired = true,
  isReceipt = false,
}) => {
  const navigate = useNavigate();

  const classes = useStyles();
  const auth = useAuth();

  const theme = useSelector(getTheme);
  return (
    <Grid
      container
      direction='row'
      justifyContent={onBackArrowClicked ? 'flex-start' : 'space-between'}
      alignItems='center'
      className={classes.header}
      style={{
        height: height,
        backgroundColor: '#fff',
      }}
      padding={
        // isReceipt && !onBackArrowClicked --> When receipt is opened through email
        companyIcon || (isReceipt && !onBackArrowClicked)
          ? '0px 24px'
          : //isReceipt && onBackArrowClicked --> When receipt is opened after session ends in webapp
          isReceipt && onBackArrowClicked
          ? '0px 16px '
          : '0px 16px 0px 8px'
      }
    >
      {onBackArrowClicked && (
        <Grid item>
          <IconButton onClick={() => onBackArrowClicked()}>
            {arrowBack(theme.primary)}
          </IconButton>
        </Grid>
      )}

      {isBackButtonRequired && (
        <Grid item paddingLeft={isReceipt && !onBackArrowClicked ? '' : '8px'}>
          {onCloseClicked && (
            <IconButton onClick={() => onCloseClicked()}>
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
          {subTitle && (
            <Typography className={classes.subTitle}>{subTitle}</Typography>
          )}
        </Grid>
      )}
      {companyIcon && <Grid>{companyIcon}</Grid>}

      {icon && (
        <Grid item style={{ marginLeft: 'auto' }}>
          <IconButton
            className={classes.accountIcon}
            onClick={() => {
              onIconClicked && onIconClicked();
            }}
          >
            {icon}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
