import { useEffect, useMemo, useState } from 'react';
import ModalContainer from 'react-modal-promise';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {
  CHARGER_STATUS,
  FetchChargerRequest,
  useFetchChargerDetailQuery,
} from '../../services/chargers.api';
import {
  useFetchActiveSessionsQuery,
  useSessionStartChargingMutation,
  useSessionStopChargingMutation,
} from '../../services/sessions.api';
import { getTheme } from '../../stores/selectors/theme.selector';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import ChargerButtons from './charger-buttons.component';
import ChargerDescriptions from './charger-details/charger-descriptions.component';
import ChargerDetails from './charger-details/charger-details.component';
import NetworkLogo from '../ui-components/network-logo.component';
import { useLazyFetchTransactionByIdQuery } from '../../services/transactions.api';
import { manageAccountIcon } from '../../assets/icons/icons';
import { ROUTES } from '../../types/routes.enum';
import { useFetchListOfPayMethodsQuery } from '../../services/payment.api';
import SignInSignUpButtons from '../ui-components/sign-in-sign-up-buttons.component';
import { ConnectorStatus } from '../../types/connector-status.enum';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CustomButton from '../react-native-material-wrapper/button.component';
import CustomCheckbox from '../ui-components/checkbox.component';
import { CodeError } from '../../constant/text.constant';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

const useStyles = makeStyles(() => ({
  container: {
    // width: '343px',
    margin: 'auto',
  },
  paper: {
    borderRadius: '8px',
    maxWidth: '343px',
  },
  XPadding: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  TopPadding: {
    paddingTop: '24px',
  },
  BottomPadding: {
    paddingBottom: '24px',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
  },
  content: {
    padding: '0px',
  },
  contentTextRoot: {
    color: '#202223',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
  },
  buttonStyles: {
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    '&:hover': {
      boxShadow: 'none',
    },
    width: '100%',
    padding: '12px 16px',
  },
  dialogActionRoot1: {
    padding: '0px 16px 0px 16px',
  },
  dialogActionRoot2: {
    padding: '0px 16px 24px 16px',
  },
  checkBoxTypography: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#202223',
  },
  displayFlex: {
    display: 'flex',
  },
  checkboxDialogContentRoot: {
    padding: '0px 16px 24px 16px',
  },
}));

export const enum BUTTON_STATUS {
  INIT,
  IDLE,
  // WAITING_SESSION,
  IN_SESSION,
  // WAITING_STOP,
}

export const Charger = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  // Session token state
  const auth = useAuth();
  // URL parameter
  const { chargerName } = useParams();

  const { data: chargerSession } = useFetchActiveSessionsQuery(chargerName, {
    pollingInterval: 5000,
  });

  const [
    triggerStartSession,
    { isError: isStartSessionError, error: startSessionError },
  ] = useSessionStartChargingMutation();

  const [triggerStopSession] = useSessionStopChargingMutation();

  const { data: chargerDetail, isSuccess } = useFetchChargerDetailQuery(
    {
      chargerName: chargerName,
      isLoggedIn: auth.isLogined,
    } as FetchChargerRequest,
    {
      pollingInterval: 5000,
    },
  );  

  const [isLoading, setIsLoading] = useState(false);
  const [showTechnicianIcon, setShowTechnicianIcon] = useState(false);
  const [selectedConnectorId, setSelectedConnectorId] = useState(null);
  const [sessionRecord, setSessionRecord] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(5000);
  const [transactionId, setTransactionId] = useState<string>();
  const [open, setOpen] = useState(false);

  const currentUserEmail = auth?.user?.attributes?.email;
  const [
    showUnlicensedPopUpUserPreference,
    setShowUnlicensedPopUpUserPreference,
  ] = useState<boolean>(() => {
    const showPopupUserPreference =
      localStorage.getItem(
        `UnlicensedChargerShowPopup_${chargerName}-${currentUserEmail}`,
      ) || '';
    return showPopupUserPreference !== ''
      ? showPopupUserPreference === 'true'
      : true;
  });
  const [unlicensedCheckbox, setUnlicensedCheckbox] = useState(false);
  const [sessionStartErrorMsg, setSessionStartErrorMsg] = useState('');

  const [
    triggerFetchTransaction,
    { data: transaction, isSuccess: isFetchTransactionSuccess },
  ] = useLazyFetchTransactionByIdQuery({
    pollingInterval: pollingInterval,
  });

  // When session start fails because of pre-auth
  // Stop loading & show error message
  useEffect(() => {
    if (isStartSessionError) {
      const error: any = startSessionError;
      // If error occurs - loading continues and page have to manually refreshed to stop loading
      // This would stop loading and enable the start session button again
      setIsLoading(false);
      if (error?.data?.errorCode === CodeError.ERR_BP_0022) {
        setSessionStartErrorMsg('session_failed_payment_error');
      }
    }
  }, [isStartSessionError]);

  // Stop fetching transaction after successful fetch
  useEffect(() => {
    if (isFetchTransactionSuccess) {
      setPollingInterval(0);
    }
  }, [isFetchTransactionSuccess]);

  // TransactionId is non-empty only when session is stopped
  // Fetch Transaction only when session is stopped
  useEffect(() => {
    if (transactionId) {
      triggerFetchTransaction(transactionId);
    }
  }, [transactionId]);

  useEffect(() => {
    if (isSuccess && !chargerDetail) {
      navigate(`/${ROUTES.FOUR_0_FOUR}`);
    }
  }, [isSuccess, chargerDetail]);

  useEffect(() => {
    if (currentUserEmail && chargerDetail) {
      const savedTechnicianViewValue =
        localStorage.getItem(
          `technicianView_${currentUserEmail}-${chargerDetail?.chargerId}`,
        ) || '';

      setShowTechnicianIcon(savedTechnicianViewValue === 'true');
    }
  }, [currentUserEmail, chargerDetail]);

  useEffect(() => {
    const isAllConnectorAvailable = chargerDetail?.ports.every(
      (port) => port.status === 'AVAILABLE',
    );
    if (!isAllConnectorAvailable) {
      const availableConnector = chargerDetail?.ports.find(
        (port) => port.status === 'AVAILABLE',
      );
      setSelectedConnectorId(availableConnector?.portId);
    }
        // Open the pop up modal after checking header from chargerDetail response
    setOpen(chargerDetail?.siteHostSignUpWebLink ? true : false);
  }, [chargerDetail]);

  const isChargingBySelf = useMemo(() => {
    return chargerSession?.some(
      (session) =>
        session.chargerId === chargerDetail?.chargerId &&
        chargerDetail.ports
          .find((port) => port.portId === session.portId)
          ?.status?.toUpperCase() === ConnectorStatus.SESSION,
    );
  }, [chargerDetail, chargerSession]);

  useEffect(() => {
    if (isLoading && isChargingBySelf) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChargingBySelf]);

  const buttonStatus = useMemo(() => {
    // charger detail is not ready yet.
    if (!chargerDetail) return BUTTON_STATUS.INIT;
    if (isChargingBySelf) {
      return BUTTON_STATUS.IN_SESSION;
    } else if (chargerDetail.isAnyConnectorAvailable) {
      return BUTTON_STATUS.IDLE;
    }
  }, [chargerDetail, chargerSession]);

  const handleConnectorChange = (connector) => {
    setSelectedConnectorId(connector);
  };

  // Charge session state
  // To determine if the stop button should be enabled / disabled when the charger is in use
  /* Default is the ChargeLab Theme, this will be updated based on the config json file */
  // The charger JSON config file should be externalized to the db level once the refactoring starts
  const theme = useSelector(getTheme);

  const { data: payment } = useFetchListOfPayMethodsQuery();

  const clearTransactionId = () => {
    setTransactionId('');
  };

  const paymentPageRedirect = () => {
    navigate(`/app/${window.domainName}/${ROUTES.PAYMENTS}`);
  };

  const startCharge = () => {
    if (
      chargerDetail.priceSchedule.type.toUpperCase() !== 'FREE' &&
      !payment?.hasPaymentMethod
    ) {
      paymentPageRedirect();
      return;
    }

    if (chargerDetail.isSingleConnector) {
      setSessionStartErrorMsg('');
      clearTransactionId();
      setIsLoading(true);
      triggerStartSession({
        chargerId: chargerDetail.chargerId,
        portId: 1,
      });
    } else {
      const isConnectorAvailable = chargerDetail.ports.find(
        (port) =>
          port.portId === String(selectedConnectorId) &&
          port.status === ConnectorStatus.AVAILABLE,
      );

      if (isConnectorAvailable) {
        setSessionStartErrorMsg('');
        clearTransactionId();
        setIsLoading(true);
        triggerStartSession({
          chargerId: chargerDetail.chargerId,
          portId: selectedConnectorId,
        });
      }
    }
  };

  const handleSessionStopped = () => {
    if (isLoading) {
      setIsLoading(false);
    }
  };
  const stopCharge = async () => {
    const session = chargerSession.find(
      (session) => session.chargerId === chargerDetail.chargerId,
    );
    setSessionRecord(session);
    if (session.sessionId) {
      setTransactionId(session.transactionId);
      setIsLoading(true);
      triggerStopSession(session.sessionId);
    }
  };

  const activeSession = useMemo(() => {
    return chargerSession?.find(
      (session) => session.chargerId === chargerDetail?.chargerId,
    );
  }, [chargerSession, chargerDetail]);
  /**
   * Modal toggles
   */
  const showLogin = () => {
    navigate(`/app/${window.domainName}/login?charger=${chargerName}`);
  };

  const showRegister = () => {
    navigate(`/app/${window.domainName}/register?charger=${chargerName}`);
  };

  const handleIconClicked = () => {
    navigate(
      `/app/${window.domainName}/${ROUTES.ACCOUNT}?charger=${chargerName}`,
    );
  };

  // Handles the click outside the modal
  const handleClose = () => {
    setOpen(true);
  };

  const handleSmartChargingBtnClick = () => {
    // When user is logged in set user preference to show popup based on 'don't show...' checkbox value
    auth.isLogined &&
      setShowUnlicensedPopUpUserPreference(() => {
        localStorage.setItem(
          `UnlicensedChargerShowPopup_${chargerName}-${currentUserEmail}`,
          (!unlicensedCheckbox).toString(),
        );
        return !unlicensedCheckbox;
      });
    // Open sitehostsignupweblink in new broswer tab
    window.open(chargerDetail?.siteHostSignUpWebLink);
    //close the modal
    setOpen(false);
  };

  const handleViewChargerBtnClick = () => {
    // When user is logged in set user preference to show popup based on 'don't show...' checkbox value
    auth.isLogined &&
      setShowUnlicensedPopUpUserPreference(() => {
        localStorage.setItem(
          `UnlicensedChargerShowPopup_${chargerName}-${currentUserEmail}`,
          (!unlicensedCheckbox).toString(),
        );
        return !unlicensedCheckbox;
      });
    //close the modal
    setOpen(false);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setUnlicensedCheckbox(checked);
  };

  if (!chargerDetail || !theme.primary) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item>
          <LoadingDots />
        </Grid>
      </Grid>
    );
  }

  return (
    <div
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: '#F2F4F6',
          width: '100%',
          maxWidth: 425,
          height: '100%',
          margin: 'auto',
        }}
      >
        <BackButtonHeader
          isBackButtonRequired={false}
          onIconClicked={handleIconClicked}
          icon={auth.isLogined ? manageAccountIcon() : null}
          companyIcon={<NetworkLogo height='28px' width='auto' />}
          height={60}
        />
        <Grid
          container
          direction='column'
          style={{ padding: '24px', backgroundColor: '#FFF', height: '100%' }}
        >
          <ChargerDetails
            charger={chargerDetail}
            showTechnicianIcon={showTechnicianIcon}
            buttonStatus={buttonStatus}
            activeSession={activeSession || sessionRecord}
            transactionId={transactionId}
            onSessionStopped={handleSessionStopped}
            handleConnectorChange={handleConnectorChange}
            selectedConnectorId={selectedConnectorId}
            transaction={transaction}
            isChargingBySelf={isChargingBySelf}
          />
          {!transactionId && (
            <ChargerDescriptions
              charger={chargerDetail}
              themeColor={theme.primary}
              isLogined={auth.isLogined}
              stopCharge={stopCharge}
              isChargingBySelf={isChargingBySelf}
              activeSession={activeSession || sessionRecord}
            />
          )}
        </Grid>
        <SignInSignUpButtons
          showLogin={showLogin}
          showRegister={showRegister}
        />
        {!(transactionId && transaction) && (
          <ChargerButtons
            startCharge={startCharge}
            stopCharge={stopCharge}
            charger={chargerDetail}
            paymentPageRedirect={paymentPageRedirect}
            buttonStatus={buttonStatus}
            loading={isLoading}
            showTechView={showTechnicianIcon}
            isChargingBySelf={isChargingBySelf}
            selectedConnectorId={selectedConnectorId}
            sessionStartErrorMsg={sessionStartErrorMsg}
            activeSession={activeSession || sessionRecord}
          />
        )}
      </div>
      {showUnlicensedPopUpUserPreference && (
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{
            container: classes.container,
            paper: classes.paper,
          }}
        >
          <DialogTitle
            classes={{
              root: clsx(classes.title, classes.XPadding, classes.TopPadding),
            }}
          >
            {t('unlicensed_charger_popup_heading_1')}
            <DialogContent classes={{ root: classes.content }}>
              <DialogContentText
                id='dialog-description-1'
                classes={{ root: classes.contentTextRoot }}
              >
                {t('unlicensed_charger_popup_content_1')}
              </DialogContentText>
            </DialogContent>
          </DialogTitle>
          <DialogActions classes={{ root: classes.dialogActionRoot1 }}>
            <CustomButton
              variant='contained'
              className={classes.buttonStyles}
              style={{
                backgroundColor: theme.brand_2,
                color: '#ffffff',
              }}
              onClick={handleSmartChargingBtnClick}
              disabled={false}
              disableElevation
            >
              {t('unlicensed_charger_popup_btn_1')}
            </CustomButton>
          </DialogActions>

          <DialogTitle
            classes={{
              root: clsx(classes.title, classes.XPadding, classes.TopPadding),
            }}
          >
            {t('unlicensed_charger_popup_heading_2')}
            <DialogContent classes={{ root: classes.content }}>
              <DialogContentText
                id='dialog-description-2'
                classes={{ root: classes.contentTextRoot }}
              >
                {t('unlicensed_charger_popup_content_2')}
              </DialogContentText>
            </DialogContent>
          </DialogTitle>
          <DialogActions
            classes={{
              root: classes.dialogActionRoot2,
            }}
          >
            <CustomButton
              variant='contained'
              className={classes.buttonStyles}
              style={{
                backgroundColor: theme.brand_0,
                color: theme.brand_2,
              }}
              onClick={handleViewChargerBtnClick}
              disabled={false}
              disableElevation
            >
              {t('unlicensed_charger_popup_btn_2')}
            </CustomButton>
          </DialogActions>
          {auth.isLogined && (
            <DialogContent
              classes={{ root: clsx(classes.checkboxDialogContentRoot) }}
            >
              <DialogContentText
                classes={{ root: classes.displayFlex }}
                component='div'
              >
                <CustomCheckbox
                  style={{ padding: '0px 8px 0px 0px' }}
                  color='primary'
                  checked={unlicensedCheckbox}
                  onChange={(event) =>
                    handleCheckboxChange(event.target.checked)
                  }
                  name='unlicensed_charger_checkbox'
                />
                <Typography className={classes.checkBoxTypography}>
                  {t('unlicensed_charger_popup_checkbox')}
                </Typography>
              </DialogContentText>
            </DialogContent>
          )}
        </Dialog>
      )}
    </div>
  );
};
