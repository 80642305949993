import React, { useEffect, useState } from 'react';
import { StyledEngineProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import _ from 'lodash';

import Routes from './Routes';
import { LoadingSpinner } from './component/ui-components/loading-spinner.component';
import { Alert } from './component/ui-components/alert.component';
import {
  useFetchAPIPrefixQuery,
  useFetchStripeKeyQuery,
} from './services/deployment.api';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StaticHelper } from './util/static.helper';
import {
  useFetchReceiptSettingsQuery,
  useFetchThemeSettingsQuery,
} from './services/env.api';
import { AuthProvider } from './hooks/useAuth';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './services/translation/i18n';
import { DomainProvider, useDomain } from './hooks/useDomain';
import { defaultTheme } from './styles/styles.util';
import { UserPreferenceProvider } from './hooks/useUserPreference';
import { elementFontOptions } from './component/payments/utils';

const WhiteLabellingApp = () => {
  const { domain } = useDomain();
  const { data: deploymentData, isSuccess: isDeploymentDataSuccess } =
    useFetchAPIPrefixQuery();
  const { data: stripeData, isSuccess: isStripeDataSuccess } =
    useFetchStripeKeyQuery();
  const { data: theme, isSuccess: isThemeSuccess } =
    useFetchThemeSettingsQuery();
  const [finalTheme, setFinalTheme] = useState(defaultTheme);

  useEffect(() => {
    setFinalTheme(createTheme(_.merge(defaultTheme, theme)));
  }, [isThemeSuccess]);

  const { data: receiptSetting, isSuccess: isReceiptSettingsSuccess } =
    useFetchReceiptSettingsQuery();

  useEffect(() => {
    if (isReceiptSettingsSuccess) {
      // tab title will be determined based on the domain
      document.title = receiptSetting.companyName || '';
      const descMeta = document.getElementsByName('description');
      if (descMeta && descMeta.length > 0) {
        descMeta[0].textContent = receiptSetting.companyName || '';
      }
    }
  }, [receiptSetting, isReceiptSettingsSuccess]);

  useEffect(() => {
    if (isStripeDataSuccess) {
      if (!StaticHelper.isStripeInited) {
        StaticHelper.stripe = loadStripe(stripeData.api_key);
        StaticHelper.isStripeInited = true;
      }
    }
  }, [isStripeDataSuccess]);

  useEffect(() => {
    const href = `/assets?resourceId=favicon&domainName=${domain}`;
    const element = document.getElementById(
      'favicon',
    ) as HTMLAnchorElement | null;
    if (element) {
      element.href = href;
    }

    const touchIcon = document.getElementById(
      'touchicon',
    ) as HTMLAnchorElement | null;
    if (touchIcon) {
      touchIcon.href = href;
    }
  }, []);

  if (
    !isDeploymentDataSuccess ||
    !StaticHelper.isStripeInited ||
    !isThemeSuccess
  ) {
    return null;
  }
  return (
    <ThemeProvider theme={finalTheme}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <StyledEngineProvider injectFirst>
            <Elements options={elementFontOptions} stripe={StaticHelper.stripe}>
              <UserPreferenceProvider>
                <Routes />
                <Alert />
                <LoadingSpinner />
              </UserPreferenceProvider>
            </Elements>
          </StyledEngineProvider>
        </AuthProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <React.StrictMode>
      <DomainProvider>
        <WhiteLabellingApp />
      </DomainProvider>
    </React.StrictMode>
  );
};

export default App;
