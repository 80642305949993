import {
  ChargerDtoV2,
  ConnectorV2,
} from '../component/charger/dto/charger.dto';
import { USER_ROLE } from '../hooks/useAuth';
import { ConnectorStatus } from '../types/connector-status.enum';
import { API_KEYS, Api } from './base.api';

export const CHARGER_STATUS = {
  AVAILABLE: 'available',
  CHARGING: 'charging',
  UHAVAILABLE: 'Unavailable',
};

const isAnyConnectorAvailable = (
  ports: ConnectorV2[],
  maintenanceFlag: boolean,
) => {
  return (
    ports?.some((port) =>
      maintenanceFlag
        ? port.statusWithoutMaintenance?.toLowerCase() ===
          ConnectorStatus.AVAILABLE.toLowerCase()
        : port.status.toLowerCase() === ConnectorStatus.AVAILABLE.toLowerCase(),
    ) || false
  );
};

const isSingleConnector = (ports: ConnectorV2[]) => {
  return ports?.length === 1 || false;
};

export interface FetchChargerRequest {
  chargerName: string;
  isLoggedIn: boolean;
}

export const chargersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchChargerDetail: builder.query<ChargerDtoV2, FetchChargerRequest>({
      query: (params: FetchChargerRequest) => ({
        url: `/core/v1/${API_KEYS.CHARGERS}?filter_eq[name]=${params.chargerName}`,
        method: 'GET',
        params: {
          role: USER_ROLE.DRIVER,
        },
        noAuthNeeded: !params.isLoggedIn,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
      ],
      transformResponse: (
        response: {
          totalCount: number;
          entities: ChargerDtoV2[];
        },
        meta: any,
      ) => {
        // Length check is needed as for non-exisitent chargers we still send fields (isAnyConnectorAvailable & isSingleConnector)
        // Which breaks UI and doesn't redirect to 404 Charger not found page
        const result =
          response?.entities?.length > 0
            ? ({
                ...response.entities[0],
                isAnyConnectorAvailable: isAnyConnectorAvailable(
                  response?.entities[0]?.ports,
                  response.entities[0].maintenanceFlag,
                ),
                isSingleConnector: isSingleConnector(
                  response?.entities[0]?.ports,
                ),
                ports: response.entities[0].ports.map((port) => {
                  /**
                   * In our code, we consistently use the actual status of the port.
                   * Therefore, when maintenanceFlag is set to true, we need to override the
                   * fake status with statusWithoutMaintenance.
                   */
                  return {
                    ...port,
                    status: response.entities[0].maintenanceFlag
                      ? port.statusWithoutMaintenance
                      : port.status,
                  };
                }),
                // In order for FE to access custom header from response:
                // Access-Control-Expose-Headers with custom header value should be present in the response
                siteHostSignUpWebLink: meta?.response?.headers.get(
                  'Site-Host-Sign-Up-Web-Link',
                ),
                // To determine if charger is owned by Test company FE needs to access custon header from response
                testCompany: meta?.response?.headers.get('Test-Company'),
              } as ChargerDtoV2)
            : null;
        return result;
      },
    }),
  }),
});

export const { useFetchChargerDetailQuery, useLazyFetchChargerDetailQuery } =
  chargersApi;
