import { createContext, useContext, useEffect, useState } from 'react';
import { useLazyFetchUserPreferencesQuery } from '../services/userPreferences.api';
import { useAuth } from './useAuth';

type ContextType = {
  userIsNotSelectedForPayGo: boolean;
};

const initalState: ContextType = {
  userIsNotSelectedForPayGo: false,
};

const UserPreferenceContext = createContext(initalState);

export const useUserPreference = () => {
  return useContext(UserPreferenceContext);
};

export const UserPreferenceProvider = (props: any) => {
  const { children } = props;
  const auth = useAuth();
  const [userIsNotSelectedForPayGo, setUserIsNotSelectedForPayGo] = useState();
  const [triggerFetchUserPreferences, { data, isSuccess }] =
    useLazyFetchUserPreferencesQuery();

  useEffect(() => {
    if (isSuccess) {
      data?.cohort && setUserIsNotSelectedForPayGo(
        JSON.parse(data.cohort).isNotPayGo,
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (auth.user) {
      triggerFetchUserPreferences();
    }
  }, [auth]);

  return (
    <UserPreferenceContext.Provider value={{ userIsNotSelectedForPayGo }}>
      {children}
    </UserPreferenceContext.Provider>
  );
};
