import { PageNotFound } from '../component/Page-404.component';
import { UserProfile } from '../component/account/user-profile.component';
import { Charger } from '../component/charger/charger.component';
import { Payment } from '../component/payments/payment.component';
import { Receipt } from '../component/receipt/receipt.component';
import Login from '../component/login/login.component';
import Register from '../component/registration/register.component';
import { ROUTES } from '../types/routes.enum';
import NoPageFound from '../component/no-page-found/no-page-found.component';
import SubmitAssociationId from '../component/submit-association-id/submit-association-id.component';
import Logout from '../component/logout/logout.component';
import { ActivateCardComponent } from '../component/rfid/activate/activateCard.component';
import { RFIDComponent } from '../component/rfid/rfid.component';
import { OrderCard } from '../component/rfid/orderCard/orderCard.component';
import { RFIDDeleteComponent } from '../component/rfid/rfidDelete.component';
import { RFIDAddComponent } from '../component/rfid/rfidAdd.component';
import TechnicianView from '../component/charger/charger-details/technicianView.component';
import Language from '../component/language/language.component';
import User from '../component/user/user.component';
import { NotificationComponent } from '../component/notification/notification.component';
import LoginInfo from '../component/login/login-info.component';
import { PlugAndChargeKeysComponent } from '../component/plug-and-charge-keys/plug-and-charge-keys.component';
import {
  PlugAndChargeKeysAddVehicleComponent
} from "../component/plug-and-charge-keys/plug-and-charge-keys-add-vehicle.component";

export type ERoute = {
  name: string;
  path: string;
  isProtected: boolean;
  component: any;
};

export const routes: ERoute[] = [
  {
    name: 'root',
    path: ROUTES.ROOT,
    isProtected: false,
    component: PageNotFound, // the entrance should be /:chargername /receipt /app/domain
  },
  {
    name: 'page_not_found',
    path: `/${ROUTES.FOUR_0_FOUR}`,
    isProtected: false,
    component: PageNotFound,
  },
  // {
  //   name: 'explore',
  //   path: `/app/:subdomain/${ROUTES.EXPLORE}`,
  //   isProtected: true,
  //   component: Explore,
  // },
  {
    name: 'payments',
    path: `/app/:subdomain/${ROUTES.PAYMENTS}`,
    isProtected: true,
    component: Payment,
  },
  {
    name: 'account',
    path: `/app/:subdomain/${ROUTES.ACCOUNT}`,
    isProtected: true,
    component: UserProfile,
  },
  {
    name: 'profile',
    path: `/app/:subdomain/${ROUTES.PROFILE}`,
    isProtected: true,
    component: User,
  },
  {
    name: 'plug-and-charge-keys',
    path: `/app/:subdomain/${ROUTES.PLUG_AND_CHARGE_KEYS}`,
    isProtected: true,
    component: PlugAndChargeKeysComponent,
  },
  {
    name: 'rfid',
    path: `/app/:subdomain/${ROUTES.RFID}`,
    isProtected: true,
    component: RFIDComponent,
  },
  {
    name: 'notification',
    path: `/app/:subdomain/${ROUTES.NOTIFICATION}`,
    isProtected: true,
    component: NotificationComponent,
  },
  {
    name: 'rfid',
    path: `/app/:subdomain/${ROUTES.DELETE_RFID}/:id`,
    isProtected: true,
    component: RFIDDeleteComponent,
  },
  {
    name: 'rfid',
    path: `/app/:subdomain/${ROUTES.ADD_RFID}`,
    isProtected: true,
    component: RFIDAddComponent,
  },
  {
    name: 'orderCard',
    path: `/app/:subdomain/${ROUTES.ORDER_CARD}`,
    isProtected: true,
    component: OrderCard,
  },
  {
    name: 'orderCard',
    path: `/app/:subdomain/${ROUTES.ACTIVATE_CARD}`,
    isProtected: true,
    component: ActivateCardComponent,
  },
  {
    name: 'plugAndChargeAddVehicle',
    path: `/app/:subdomain/${ROUTES.PLUG_AND_CHARGE_ADD_VEHICLE}`,
    isProtected: true,
    component: PlugAndChargeKeysAddVehicleComponent,
  },
  // {
  //   name: 'charger_active',
  //   path: `/app/:subdomain/${ROUTES.CHARGER_ACTIVE}`,
  //   isProtected: false,
  //   component: Charge,
  // },
  {
    name: 'receipts_receiptId',
    path: `/${ROUTES.RECEIPTS}/:receiptId`,
    isProtected: false,
    component: Receipt,
  },
  // {
  //   name: 'history',
  //   path: `/app/:subdomain/${ROUTES.HISTORY}`,
  //   isProtected: true,
  //   component: History,
  // },
  {
    name: 'login',
    path: `/app/:subdomain/${ROUTES.LOGIN}`, //`/app/${domainName}/${ROUTES.LOGIN}`,
    isProtected: false,
    component: Login,
  },
  // {
  //   name: "login_chargerName",
  //   path: `/app/:subdomain/${ROUTES.LOGIN}/:chargerName`,
  //   isProtected: false,
  //   component: Login,
  // },
  {
    name: 'register',
    path: `/app/:subdomain/${ROUTES.REGISTER}`,
    isProtected: false,
    component: Register,
  },
  {
    name: 'tech_view',
    path: `/app/:subdomain/${ROUTES.TECHNICIAN_VIEW}`,
    isProtected: true,
    component: TechnicianView,
  },
  {
    name: 'logout',
    path: `/app/:subdomain/${ROUTES.LOGOUT}`,
    isProtected: true,
    component: Logout,
  },
  // {
  //   name: "register",
  //   path: `/app/:subdomain/${ROUTES.REGISTER}/:chargerName`,
  //   isProtected: false,
  //   component: Register,
  // },
  {
    name: 'chargers_name',
    path: '/:chargerName',
    isProtected: false,
    component: Charger,
  },
  // {
  //   name: "chargers_Name_locationId",
  //   path: "/:chargerName/:locationId",
  //   isProtected: false,
  //   component: SitePage,
  // },
  {
    name: 'login',
    path: `/${ROUTES.LOGIN}`,
    isProtected: false,
    component: Login,
  },
  {
    name: 'register',
    path: `/${ROUTES.REGISTER}`,
    isProtected: false,
    component: Register,
  },
  {
    name: 'registerHomeId',
    path: `/app/:subdomain/${ROUTES.REGISTER_HOME_ID}`,
    isProtected: false,
    component: Register,
  },
  {
    name: 'loginHomeId',
    path: `/app/:subdomain/${ROUTES.LOGIN_HOME_ID}`,
    isProtected: false,
    component: Login,
  },
  {
    name: 'association_id',
    path: `/app/:subdomain/${ROUTES.ASSOCIATION}`,
    isProtected: true,
    component: SubmitAssociationId,
  },
  {
    name: 'language',
    path: `/app/:subdomain/${ROUTES.LANGUAGE}`,
    isProtected: true,
    component: Language,
  },
  {
    name: 'no_page_found',
    path: ROUTES.NO_PAGE_FOUND,
    isProtected: false,
    component: NoPageFound,
  },
  {
    name: 'login_info',
    path: `/app/:subdomain/${ROUTES.LOGIN_INFO}`,
    isProtected: false,
    component: LoginInfo,
  },
];
