import React from 'react';
import NetworkLogo from '../ui-components/network-logo.component';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import CustomPhoneInput from '../react-native-material-wrapper/phone-input.component';
import SubmitCancelButtons from '../ui-components/submit-cancel-buttons.component';
import { authStyles } from '../login/authStyles';
import { SUB_ROUTES } from '../../types/routes.enum';
import { CardElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import CustomButton from '../react-native-material-wrapper/button.component';
import CustomCheckbox from '../ui-components/checkbox.component';
import clsx from 'clsx';

interface IProps {
  handleInputChange: Function;
  isHomeId: boolean;
  registerUser: Function;
  loading: boolean;
  signIn: Function;
  registrationForm: any;
  validateForm: () => boolean;
  countryCode: string;
}
const RegisterForm = ({
  handleInputChange,
  isHomeId,
  registerUser,
  loading,
  signIn,
  registrationForm,
  validateForm,
  countryCode,
}: IProps) => {
  const theme = useSelector(getTheme);
  
  const { i18n } = useTranslation();
  const classes = authStyles();

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: 'Inter',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#818181',
        },
      },
      invalid: {
        color: 'red',
        iconColor: 'red',
      },
    },
  };

  const redirectTerms = () => {
    window.open('https://chargelab.co/driver-and-site-host-terms#driver');
  };
  const redirectPolicy = () => {
    window.open('https://chargelab.co/driver-and-site-host-terms#privacy-policy');
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 30,
        maxWidth: 425,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <NetworkLogo width={'auto'} />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            align='center'
            className={classes.ivySecondartyText}
            style={{
              marginTop:
                window.domainName.toLowerCase() === SUB_ROUTES.IVY.toLowerCase()
                  ? 33
                  : 16,
            }}
          >
            {window.domainName.toLowerCase() === SUB_ROUTES.IVY.toLowerCase()
              ? i18n.t('ivy_sign_up')
              : i18n.t('sign_up_to_start_charging')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            className={classes.inputWrapper}
            onChange={(e) => handleInputChange('firstName', e.target.value)}
            placeholder={i18n.t('first_name')}
            variant='filled'
            InputProps={{ disableUnderline: true }}
            required
            autoFocus
            name='first_name'
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            className={classes.inputWrapper}
            onChange={(e) => handleInputChange('lastName', e.target.value)}
            placeholder={i18n.t('last_name')}
            variant='filled'
            InputProps={{ disableUnderline: true }}
            required
            name='last_name'
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            className={classes.inputWrapper}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder={i18n.t('email')}
            variant='filled'
            required
            InputProps={{ disableUnderline: true }}
            fullWidth={true}
            name='email'
          />
        </Grid>
        <Grid item xs={12} className={classes.phoneInput}>
          <CustomPhoneInput
            style={{ display: 'flex', marginBottom: 15 }}
            inputProps={{
              required: true,
            }}
            inputStyle={{ width: '85vw', height: 55, fontSize: 16 }}
            country={countryCode}
            value={registrationForm.phoneNumber}
            onChange={(e) => handleInputChange('phoneNumber', e)}
          />
        </Grid>
      </Grid>

      {isHomeId && (
        <div
          style={{
            border: '1px solid #c4c4c4',
            borderRadius: '4px',
            height: 55,
            boxSizing: 'border-box',
            padding: '17px 12px',

            paddingLeft: 12,
          }}
        >
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      )}

      <Grid item xs={12} className={clsx([classes.MT24])}>
        <FormControlLabel
          control={
            <CustomCheckbox
              color='primary'
              checked={registrationForm.isTermsAccepted}
              onChange={(event) =>
                handleInputChange('isTermsAccepted', event.target.checked)
              }
              name='accept_terms'
              style={{ padding: '0px 9px' }}
            />
          }
          label={i18n.t('i_agree_to_the')}
          sx={{
            '& .MuiTypography-root': {
              // Apply custom styles to the label typography
              fontWeight: 400,
              color: '#202223',
              fontFamily: 'Inter',
              fontSize: 16,
              lineHeight: '24px',
            },
          }}
        />
        <CustomButton
          size='large'
          type='link'
          style={{
            color: theme.primary,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center',
            fontFamily: 'Inter',
            padding: 0,
            left: '-12px',
            textTransform: 'inherit',
          }}
          onClick={redirectTerms}
        >
          {i18n.t('terms_of_service')}
        </CustomButton>
        <span style={{fontWeight: 400,
              color: '#202223',
              fontFamily: 'Inter',
              fontSize: 16,
              lineHeight: '24px', position: 'relative', left: '-6px', top: '2px'}}>{i18n.t('and_text')}</span>
        <CustomButton
          size='large'
          type='link'
          style={{
            color: theme.primary,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center',
            fontFamily: 'Inter',
            padding: 0,
            left: '23px',
            textTransform: 'inherit',
          }}
          onClick={redirectPolicy}
        >
          {i18n.t('privacy_policy')}
        </CustomButton>
      </Grid>
      <SubmitCancelButtons
        submit={registerUser}
        cancel={signIn}
        disabled={!validateForm()}
        submitText={i18n.t('next')}
        cancelText={i18n.t('sign_in_instead')}
        loading={loading}
        theme={theme}
      />
      <Grid>
        <Typography className={clsx(classes.description, classes.size14)}>
          {i18n.t('sign_up_receive_stop_text_messages', {
            company: window.domainName,
          })}
        </Typography>
      </Grid>
    </div>
  );
};

export default RegisterForm;
