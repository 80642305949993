import { makeStyles } from '@mui/styles';

export const authStyles = makeStyles(() => ({
  authWrapper: {
    padding: 24,
    maxWidth: 425,
  },
  formInput: {
    marginTop: 16,
    marginBottom: 10,
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#202223',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#202223',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#202223',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#F2F4F6',
      height: '48px',
      boxSizing: 'border-box',
    },
  },
  mt8: {
    marginTop: '8px',
  },
  mt16: {
    marginTop: '16px',
  },
  mt32: {
    marginTop: '32px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    zIndex: -1,
  },
  description: {
    marginTop: 16,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Inter',
    color: '#6B7684',
  },
  size14: {
    fontSize: 12,
  },
  termsDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: '#6B7684',
    lineHeight: '20px',
    fontFamily: 'Inter',
  },
  cardwrapper: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    padding: '16px',
  },
  primaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  secondaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  ivySecondartyText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
  },
  ivyDescText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  MT24: {
    marginTop: 24,
  },
  inputWrapper: {
    border: '2px solid transparent',
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
      border: '2px solid #F2F4F6',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
      border: '2px solid #F2F4F6',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
    '& .Mui-focused': {
      border: '2px solid #000000',
    },
  },
  phoneInput: {
    '& .react-tel-input .arrow': {
      // width: 10,
      // height: 15,
    },
    '& .react-tel-input': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    '& .react-tel-input .form-control': {
      marginLeft: 8,
      width: '100%',
      height: 48,
      padding: '12px 16px',
      border: '2px solid transparent',
      background: '#F2F4F6',
    },
    '& .react-tel-input .form-control:focus': {
      border: '2px solid #000000',
    },
    '& .react-tel-input .flag-dropdown': {
      position: 'relative',
      border: '2px solid transparent',
      borderRadius: '4px',
      // background: '#F2F4F6',
    },
    '& .react-tel-input .selected-flag': {
      width: 44,
      border: '2px solid transparent',
      background: '#F2F4F6',
    },
    '& .react-tel-input .flag-dropdown .open': {
      width: 44,
      border: '2px solid #000000',
      borderRadius: '4px',
      boxSizing: 'border-box',
      background: '#F2F4F6',
    },
  },

  termsWrapper: {
    marginTop: '6px',
    maxWidth: 425,
  },
  callSupportBtn: {
      display: 'flex',
      margin: '32px 0px 24px 0px',
      justifyContent: 'center',
      height: '26px',
      alignItems: 'center',
  },
  absolute: {
    position: 'absolute',
  }
}));
