/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
} from 'react';
import { Hub, Auth, Amplify } from 'aws-amplify';
// import { registerLocale, setDefaultLocale } from "react-datepicker";
import cryptoRandomString from 'crypto-random-string';
import { useDispatch } from 'react-redux';
import { validateEmail } from './utils';
// import { httpRawGet } from "../services/http/http.service";
// import { i18n } from "../services/translation/i18n";
// import { Snackbar } from "../components/_ui";
// import {
//   AlertPosition,
//   AlertType,
// } from "../components/_ui/snack-bar/Snack-Bar.component";
import { Api } from '../services/base.api';
import { useFetchCognitoQuery } from '../services/deployment.api';
import { useTranslation } from 'react-i18next';
import { UserPersonalInfo } from '../stores/types/user.types';
import { LANGUAGES, i18n } from '../services/translation/i18n';

export enum USER_ROLE {
  SUPPORT = 'SUPPORT',
  NORMAL = 'NORMAL',
  DRIVER = 'DRIVER',
}

type ContextType = {
  signIn: Function;
  signOut: Function;
  signUp: Function;
  resendCode: Function;
  verifyCode: Function;
  setToken: Function;
  cleanError: Function;
  setCognitoUser: Function;
  setUser: Function;
  updateUserLanguage: Function;
  updateCompanyId: Function;
  cognitoUser: any;
  user: any;
  role: string;
  token: string;
  error: string;
  isLogined: boolean;
  refreshUserData: Function;
  updateUserName: Function;
  updateUserAddress: Function;
};

const initalState: ContextType = {
  signIn: () => {},
  signOut: () => {},
  signUp: () => {},
  resendCode: () => {},
  verifyCode: () => {},
  setToken: () => {},
  cleanError: () => {},
  setCognitoUser: () => {},
  setUser: () => {},
  updateUserLanguage: () => {},
  updateCompanyId: () => {},
  updateUserName: () => {},
  updateUserAddress: () => {},
  cognitoUser: {},
  user: {
    attributes: {
      sub: '',
      email_verified: true,
      phone_number_verified: true,
      email: '',
      family_name: '',
      given_name: '',
      phone_number: '',
      profile: {
        sitehostDashboardLanguage: 'en-US',
        lastActiveCompanyId: '',
        acceptedDriverTerms: '1.0',
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        isoCode: '',
      },
    },
    username: '',
  },
  role: USER_ROLE.NORMAL,
  token: '',
  error: '',
  isLogined: false,
  refreshUserData: () => {},
};

const transformUserInfo = (newUserinfo: any) => {
  let profile;
  let address;
  if (newUserinfo.attributes?.profile) {
    profile = JSON.parse(newUserinfo.attributes.profile);
  }
  newUserinfo.attributes.profile = {
    sitehostDashboardLanguage: profile?.sitehostDashboardLanguage || 'en-US',
    lastActiveCompanyId: profile?.lastActiveCompanyId || '',
  };
  if (newUserinfo.attributes?.address) {
    address = JSON.parse(newUserinfo.attributes.address);
  }
  newUserinfo.attributes.address = {
    addressLine1: address?.addressLine1 || '',
    addressLine2: address?.addressLine2 || '',
    city: address?.city || '',
    province: address?.province || '',
    country: address?.country || '',
    postalCode: address?.postalCode || '',
    isoCode: address?.isoCode || '',
  };

  return newUserinfo;
};

const useProvideAuth = () => {
  const [user, setUser] = useState<any>(null);
  const [role, setRole] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const isLogined = useMemo(() => {
    return user != null;
  }, [user]);

  const signIn = (email: string) => {
    if (!email) {
      setError('Please enter your email or phone number.');
      return;
    }

    Auth.signIn(email.trim())
      .then((data) => {
        Auth.sendCustomChallengeAnswer(data, email.trim(), {
          isEmail: `${validateEmail(email)}`,
        }).then((newCognitoUser) => {
          setCognitoUser(newCognitoUser);
        });
      })
      .catch((e: any) => setError(e.message));
  };

  const signUp = async (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) => {
    try {
      await Auth.signUp({
        username: cryptoRandomString({ length: 16, type: 'alphanumeric' }),
        password: cryptoRandomString({ length: 120, type: 'ascii-printable' }), // May have a chance to not comply with the Cognito password rules
        attributes: {
          given_name: firstName,
          family_name: lastName,
          email: email.trim(),
          phone_number: phoneNumber,
          profile: JSON.stringify({
            acceptedDriverTerms: '1.0',
          }),
        },
      });
    } catch (e: any) {
      console.log('sign up error:', e);
      setError(e.message);
      throw new Error(e.message);
    }
  };

  const updateUserLanguage = async (lang: string) => {
    const newProfile = {
      ...user.attributes.profile,
      sitehostDashboardLanguage: lang,
    };
    Auth.updateUserAttributes(cognitoUser, {
      profile: JSON.stringify(newProfile),
    });
    user.attributes.profile = newProfile;
    setUser(user);
    i18n.changeLanguage(lang);
    // setDefaultLocale(lang);
  };

  const updateCompanyId = async (companyId: string) => {
    const newProfile = {
      ...user.attributes.profile,
      lastActiveCompanyId: companyId,
    };
    user.attributes.profile = newProfile;
    dispatch(Api.util.resetApiState());
    setUser(user);
    await Auth.updateUserAttributes(cognitoUser, {
      profile: JSON.stringify(newProfile),
    });
  };

  const refreshUserData = async () => {
    const userInfo = await Auth.currentUserInfo();
    setUser(transformUserInfo(userInfo));
  };

  const resendCode = () => {
    Auth.signIn(cognitoUser.username)
      .then((data) => {
        Auth.sendCustomChallengeAnswer(data, cognitoUser.username, {
          isEmail: `${validateEmail(cognitoUser.username)}`,
        }).then((newCognitoUser) => {
          setCognitoUser(newCognitoUser);
        });
      })
      .catch((e: any) => setError(e.message));
  };

  const verifyCode = (code: string) => {
    Auth.sendCustomChallengeAnswer(cognitoUser, code).catch((e) => {
      setError(`verifyCode failed: ${e.message}, ${e}`);
    });
  };

  const signOut = (redirectSignOut: Function) => {
    try {
      Auth.signOut()
        .then(() => {
          redirectSignOut();
        })
        .catch(() => {});
      dispatch(Api.util.resetApiState());
      localStorage.clear();
      setCognitoUser(null);
      setUser(null);
      setToken('');
    } catch (e: any) {
      setError(`error signing out: ${e.message}`);
    }
  };

  useEffect(() => {
    if (user) {
      if (user?.attributes['custom:legacy_support_da'] === 'ALLOW') {
        setRole(USER_ROLE.SUPPORT);
      } else {
        setRole(USER_ROLE.NORMAL);
      }
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setCognitoUser(data.payload.data);
          Auth.currentUserInfo()
            .then((signInUserInfo) => {
              const transFormedRes = transformUserInfo(signInUserInfo);
              setUser(transFormedRes);
              i18n.changeLanguage(
                transFormedRes?.attributes?.profile?.sitehostDashboardLanguage
              );
            })
            .catch((e) => {});
          break;
        case 'signUp':
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          break;
        case 'configured':
          break;
        default:
          break;
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const updateUserName = async (givenName: string, familyName: string) => {
    const newUserAttributes = {
      ...user.attributes,
      given_name: givenName,
      family_name: familyName,
    };
    user.attributes = newUserAttributes;
    setUser(user);
    await Auth.updateUserAttributes(cognitoUser, {
      family_name: newUserAttributes.family_name,
      given_name: newUserAttributes.given_name,
    });
  };

  const updateUserAddress = async (userInfo: UserPersonalInfo) => {
    const newAddress = {
      addressLine1: userInfo.address.addressLine1,
      addressLine2: userInfo.address?.addressLine2 || '',
      city: userInfo.address.city,
      province: userInfo.address.province,
      country: userInfo.address.country,
      postalCode: userInfo.address.postalCode,
      isoCode: userInfo.address.isoCode,
    };
    user.attributes = { ...user.attributes, address: newAddress };
    setUser(user);
    await Auth.updateUserAttributes(cognitoUser, {
      address: JSON.stringify(newAddress),
    });
  };

  const cleanError = () => setError('');
  // Return the user object and auth methods
  return {
    user,
    cognitoUser,
    role,
    token,
    error,
    signIn,
    signUp,
    signOut,
    setToken,
    resendCode,
    verifyCode,
    cleanError,
    setCognitoUser,
    setUser,
    updateUserLanguage,
    updateCompanyId,
    isLogined,
    refreshUserData,
    updateUserName,
    updateUserAddress,
  };
};

const authContext = createContext(initalState);

export const useAuth = () => {
  return useContext(authContext);
};

export const AuthProvider = (props: any) => {
  const { children } = props;
  const auth = useProvideAuth();
  const [isConfigInited, setIsConfigInited] = useState(false);

  const { data: dep, isSuccess, isError } = useFetchCognitoQuery();

  const setupCognito = async () => {
    Amplify.configure({
      Auth: {
        region: dep.region,
        userPoolId: dep.userPoolId,
        userPoolWebClientId: dep.clientId,
        mandatorySignIn: false,
        authenticationFlowType: 'CUSTOM_AUTH',
      },
    });
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      auth.setCognitoUser(cognitoUser);

      const userInfo = await Auth.currentUserInfo();
      auth.setUser(transformUserInfo(userInfo));
      i18n.changeLanguage(
        userInfo?.attributes?.profile?.sitehostDashboardLanguage || LANGUAGES[0]
      );
      const cognitoUserSession = await Auth.currentSession();
      const token = cognitoUserSession.getAccessToken().getJwtToken();
      auth.setToken(token);
    } catch (e: any) {
      console.log('get auth user error:', e.message);
    }
    setIsConfigInited(true);
  };

  useEffect(() => {
    if (isSuccess) {
      setupCognito();
    }
  }, [isSuccess, isError]);

  if (!isConfigInited) {
    return null;
  }
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
