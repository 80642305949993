import React, { useEffect, useMemo, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { showSnackBar } from '../../util/toast.utility';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { CodeVerification } from '../code-verification/code-verification.component';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../types/routes.enum';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useAuth } from '../../hooks/useAuth';
import { getIsEmail, getLoginString } from './utils';
import LoginForm from './login-form.component';
import { useDomain } from '../../hooks/useDomain';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Login = () => {
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const [phoneNumberOrEmailLogin, setPhoneNumberOrEmailLogin] = useState('');
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState(null);

  const [confirmCode, setConfirmCode] = useState(false);
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');
  const association = new URLSearchParams(location.search).get('associationId');
  const theme = useSelector(getTheme);

  const auth = useAuth();
  const { domain } = useDomain();

  const isHomeId = useMemo(() => {
    return location?.pathname
      ?.toLowerCase()
      .includes(`${ROUTES.LOGIN_HOME_ID}`);
  }, [location]);

  useEffect(() => {
    if (auth.isLogined) {
      if (chargerName) {
        navigate(`/${chargerName}`);
      } else if (isHomeId) {
        const url = association
          ? `/app/${domain}/${ROUTES.ASSOCIATION}?associationId=${association}`
          : `/app/${domain}/${ROUTES.ASSOCIATION}`;
        navigate(url);
      } else {
        navigate(`/app/${domain}/${ROUTES.ACCOUNT}`);
      }
    }
  }, [auth, chargerName]);

  const showRegisterInsideLogin = () => {
    const route = isHomeId
      ? association
        ? `/app/${domain}/${ROUTES.REGISTER_HOME_ID}?associationId=${association}`
        : `/app/${domain}/${ROUTES.REGISTER_HOME_ID}`
      : chargerName
      ? `/app/${domain}/${ROUTES.REGISTER}?charger=${chargerName}`
      : `/app/${domain}/${ROUTES.REGISTER}`;
    navigate(route);
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      await signIn();
    }
  };

  const signIn = async () => {
    try {
      setLoading(true);

      const isEmail = getIsEmail(phoneNumberOrEmailLogin);
      const loginString = getLoginString(phoneNumberOrEmailLogin);

      const user = await Auth.signIn(loginString);
      setNewUser(user);
      // Just to be able to set the clientMetadata isEmail flag
      await Auth.sendCustomChallengeAnswer(user, loginString, {
        isEmail: '' + isEmail,
      });

      setConfirmCode(true);
    } catch (err) {
      setLoading(false);
      showSnackBar(i18n.t('invalid_email_or_phone'));
    }
  };

  const handleClosebutton = () => {
    navigate(`/${chargerName}`);
  };

  return (
    <ViewWrapper>
      {chargerName && (
        <Grid
          container
          justifyContent='flex-end'
          style={{ padding: 16, paddingBottom: 0 }}
        >
          <IconButton style={{}} onClick={handleClosebutton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
      {!confirmCode ? (
        <LoginForm
          handleKeyPress={handleKeyPress}
          loading={loading}
          theme={theme}
          signIn={signIn}
          setPhoneNumberOrEmailLogin={setPhoneNumberOrEmailLogin}
          showRegisterInsideLogin={showRegisterInsideLogin}
        />
      ) : (
        <CodeVerification
          theme={theme}
          phoneNumberOrEmailLogin={getLoginString(phoneNumberOrEmailLogin)}
          chargerName={chargerName}
          user={newUser}
          isEmail={getIsEmail(phoneNumberOrEmailLogin)}
        />
      )}
    </ViewWrapper>
  );
};

export default Login;
