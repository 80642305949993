import { Api, API_KEYS } from './base.api';
import {
  EditPaymentPayload,
  Payment,
  PaymentPayload,
} from '../stores/types/payment.interface';

export const paymentApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchListOfPayMethods: builder.query<Payment, void>({
      query: () => ({
        url: API_KEYS.PAYMENT,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.PAYMENT, id: 'LIST' },
      ],
      transformResponse: (response: Payment) => {
        return {
          payload: response.payload,
          hasPaymentMethod: response.payload?.length > 0,
        } as Payment;
      },
    }),
    makePayMethodDefault: builder.mutation<Payment, any>({
      query: (payload) => ({
        url: `${API_KEYS.PAYMENT}/${payload.id}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PAYMENT, id: 'LIST' },
      ],
    }),
    removePayMethods: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${API_KEYS.PAYMENT}/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PAYMENT, id: 'LIST' },
      ],
    }),
    addPayMethod: builder.mutation<any, any>({
      query: (payload) => ({
        url: API_KEYS.PAYMENT,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PAYMENT, id: 'LIST' },
      ],
    }),
    editPaymentMethod: builder.mutation<PaymentPayload, EditPaymentPayload>({
      query: (payload) => ({
        url: `${API_KEYS.PAYMENT}/${payload.id}`,
        method: 'PATCH',
        body: payload.body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PAYMENT, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useFetchListOfPayMethodsQuery,
  useLazyFetchListOfPayMethodsQuery,
  useMakePayMethodDefaultMutation,
  useRemovePayMethodsMutation,
  useAddPayMethodMutation,
  useEditPaymentMethodMutation,
} = paymentApi;
