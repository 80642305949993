import CustomButton from '../react-native-material-wrapper/button.component';
import 'react-phone-input-2/lib/style.css';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import { authStyles } from './authStyles';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../ui-components/auth-header.component';
import { SUB_ROUTES } from '../../types/routes.enum';
import LoginTerms from './login-terms.component';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

interface IProps {
  handleKeyPress: Function;
  signIn: Function;
  showRegisterInsideLogin: Function;
  setPhoneNumberOrEmailLogin: (value: string) => void;
  loading: boolean;
  theme: any;
}
const LoginForm = ({
  handleKeyPress,
  loading,
  theme,
  signIn,
  setPhoneNumberOrEmailLogin,
  showRegisterInsideLogin,
}: IProps) => {
  const classes = authStyles();
  const { i18n } = useTranslation();

  const isIvy =
    window.domainName.toLowerCase() === SUB_ROUTES.IVY.toLowerCase();
  return (
    <div className={classes.authWrapper}>
      <AuthHeader
        primaryText={isIvy ? '' : i18n.t('log_in')}
        secondaryText={
          isIvy ? i18n.t('ivy_log_in') : i18n.t('lets_get_started')
        }
        primaryTextClassName={classes.primaryText}
        secondaryTextClassName={
          isIvy ? classes.ivySecondartyText : classes.secondaryText
        }
      />
      <div className={classes.formInput}>
        <CustomTextField
          style={{ width: '100%' }}
          onChange={(e) => setPhoneNumberOrEmailLogin(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={i18n.t('email_address_or_phone')}
          variant='outlined'
          required
          autoFocus
          disabled={loading}
        />
      </div>
      <div className={classes.buttonGroup}>
        <CustomButton
          size='large'
          style={{
            color: theme.primary,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center',
            fontFamily: 'Inter',
            textTransform: 'inherit',
            padding: '0px',
          }}
          onClick={showRegisterInsideLogin}
        >
          {i18n.t('sign_up')}
        </CustomButton>
        <CustomButton
          variant='contained'
          size='large'
          style={{
            backgroundColor: loading ? theme.brand_0 : theme.primary,
            color: '#ffffff',
            width: '76px',
            height: '48px',
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'inherit',
          }}
          onClick={signIn}
          disabled={loading}
        >
          {i18n.t('next')}
          {loading && <LoadingDots className={classes.absolute}/>}
        </CustomButton>
      </div>
      <LoginTerms />
    </div>
  );
};

export default LoginForm;
