import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { submitIfEnterIsClicked } from '../../util/js-utility';
import CustomButton from '../react-native-material-wrapper/button.component';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import { Auth } from 'aws-amplify';
import parsePhoneNumber from 'libphonenumber-js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { showSnackBar } from '../../util/toast.utility';
import { useTranslation } from 'react-i18next';
import { useFetchReceiptSettingsQuery } from '../../services/env.api';
import { useAuth } from '../../hooks/useAuth';
import AuthHeader from '../ui-components/auth-header.component';
import { authStyles } from '../login/authStyles';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

export const CodeVerification = ({
  close = null,
  theme = null,
  phoneNumberOrEmailLogin = '',
  chargerName = '',
  user = {},
  isEmail = false,
  confirm = false,
}) => {
  const [otp, setOtp] = useState('');
  const { i18n } = useTranslation();
  const classes = authStyles();
  const [loading, setLoading] = useState(false);
  // Separate loading state to prevent the loading circle to show up on both buttons
  const [loadingResendOtp, setLoadingResentOtp] = useState(false);
  const [otpResendSuccess, setOtpResendSuccess] = useState(false);
  // For some reason, when the OTP submitted is not correct, the user session will be invalid the next time the user tried to re-enter the correct code,
  // so the user needs to click the resend link and we need to set the new user returned by cognito
  const [otpNotReceivedUser, setOtpNotReceivedUser] = useState(user);

  // For UI display
  const [formattedPhoneNumberOrEmail, setFormattedPhoneNumberOrEmail] =
    useState('');

  const { data: receiptSettings } = useFetchReceiptSettingsQuery();
  const auth = useAuth();

  // useEffect(() => {
  //   const getNetwork = async () => {
  //     const response = await httpGetAssets(
  //       `?resourceId=receipt-settings&chargerId=${chargerName}`
  //     ).catch(console.error);
  //     setNetwork(response?.companyName);
  //   };
  //   getNetwork();
  // }, [chargerName]);

  const submit = useCallback(async () => {
    if (otp.length !== 5) {
      return;
    }

    setLoading(true);
    const loggedInUser = await Auth.sendCustomChallengeAnswer(
      otpNotReceivedUser,
      otp
    ).catch((error) => {
      setLoading(false);
      if (error.message === 'Incorrect username or password.') {
        // Happens when the first attempt the user input is invalid code (didn't type correctly)
        showSnackBar(i18n.t('Sign_in_failed'));
      } else if (error.message === 'Invalid session for the user.') {
        // Happens when the first attempt is invalid code, then on the second correct the user tries correct code
        showSnackBar(i18n.t('one_time_code_is_no_longer_valid'), true);
        setOtp('');
      } else {
        showSnackBar(error.message);
      }
      console.log(error.message);
    });

    if (loggedInUser) {
      const sessionToken = loggedInUser.signInUserSession.accessToken.jwtToken;
      auth.setToken(sessionToken);
      if (close) {
        await close();
      }
    }
  }, [close, otp, otpNotReceivedUser, chargerName, i18n]);

  const resendOtp = async () => {
    setOtpResendSuccess(false);
    setLoadingResentOtp(true);
    // Login the user again to resend the otp, the user returned by this call should be set to replace the old user object
    const user = await Auth.signIn(
      !isEmail ? phoneNumberOrEmailLogin : phoneNumberOrEmailLogin
    );
    await Auth.sendCustomChallengeAnswer(
      user,
      !isEmail ? phoneNumberOrEmailLogin : phoneNumberOrEmailLogin,
      {
        isEmail: '' + isEmail, // make the boolean value as a string
      }
    );
    // Replace the old user with the new returned user
    setOtpNotReceivedUser(user);
    setOtpResendSuccess(true);
    showSnackBar(
      i18n.t('code_sent') +
        ' ' +
        (isEmail ? i18n.t('email') : i18n.t('phone_number'))
    );
    setLoadingResentOtp(false);
  };

  const formatPhoneNumber = (phone) => {
    const cleanPhone = phone.indexOf('+') < 0 ? '+' + phone : phone;
    const phoneNumber = parsePhoneNumber(cleanPhone);
    return phoneNumber.formatNational();
  };

  useEffect(() => {
    setFormattedPhoneNumberOrEmail(
      isEmail
        ? phoneNumberOrEmailLogin
        : formatPhoneNumber(phoneNumberOrEmailLogin)
    );
  }, [isEmail, phoneNumberOrEmailLogin]);

  return (
    <div className={classes.authWrapper}>
      <AuthHeader
        primaryText={formattedPhoneNumberOrEmail}
        secondaryText={i18n.t('enter_the_code_you_received')}
      />
      <div className={classes.formInput}>
        <CustomTextField
          style={{ width: '100%' }}
          onChange={(e) => setOtp(e.target.value)}
          onKeyDown={(e) => submitIfEnterIsClicked(e, submit)}
          onKeyUp={(e) => submit()}
          variant='outlined'
          required
          autoFocus
          placeholder={i18n.t('enter_code')}
          inputProps={{ inputMode: 'numeric', maxLength: 5 }}
          disabled={loading}
        />
        <Typography className={classes.description} variant='subtitle2'>
          {i18n.t('passwordless_help_text', {
            network: receiptSettings.companyName || '',
          })}
        </Typography>
      </div>
      <div className={classes.buttonGroup}>
        <CustomButton
          style={{
            color: loadingResendOtp ? theme.brand_0 : theme.primary,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'center',
            fontFamily: 'Inter',
            textTransform: 'inherit',
            padding: '0px',
          }}
          size='medium'
          onClick={resendOtp}
          disabled={loadingResendOtp}
        >
          {i18n.t('resend_code')}
          {loadingResendOtp && (
            <LoadingDots className={classes.absolute}/>
          )}
          {otpResendSuccess && <CheckCircleIcon style={{ color: '#8bc34a' }} />}
        </CustomButton>
        <CustomButton
          variant='contained'
          size='medium'
          style={{
            backgroundColor: loading ? theme.brand_0 : theme.primary,
            color: '#ffffff',
            bottom: 0,
            height: '48px',
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'inherit',
          }}
          onClick={submit}
          disabled={false}
        >
          {loading ? (
            <LoadingDots className={classes.absolute}/>
          ) : confirm ? (
            i18n.t('confirm')
          ) : (
            i18n.t('sign_in')
          )}
        </CustomButton>
      </div>
    </div>
  );
};
