import { Button, Grid } from '@mui/material';
import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { LoadingDots } from './loading-dots/loading-dots.component';

const useStyles = makeStyles(() => ({
  absolute: {
    position: 'absolute',
  }
}));

const CancelButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Inter',
    height: '48px',
    padding: '6px 0px',
    lineHeight: 1.5,
    color: '#E53935',
    border: '1px solid transparent',
    backgroundColor: '#FFFFFF',
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
})(Button);

const NextButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Inter',
    height: '48px',
    padding: '6px 12px',
    lineHeight: 1.5,
    color: '#4E5968',
    backgroundColor: '#F2F4F6',
    '&:hover': {
      backgroundColor: '#E5E8EB',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F2F4F6',
    },
  },
})(Button);

const SubmitCancelButtons = ({
  loading,
  submit,
  submitText,
  cancel = null,
  cancelText,
  theme,
  disabled = false,
}) => {
  const classes = useStyles();
  const getBgColor = () => {
    if(loading) {
      return theme.brand_0;
    };
    if(disabled) {
      return '#ffffff';
    }
    return theme.primary;
  }
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
      }}
    >
      <CancelButton
        size='large'
        style={{
          color: theme.primary,
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '0em',
          textAlign: 'center',
          fontFamily: 'Inter',
          textTransform: 'inherit',
        }}
        onClick={cancel}
      >
        {cancelText}
      </CancelButton>
      <NextButton
        variant='contained'
        size='large'
        style={{
          backgroundColor: getBgColor(),
          color: disabled ? '#D1D6DB' : '#ffffff',
          textTransform: 'inherit',
        }}
        onClick={submit}
        disabled={loading || disabled}
      >
        {submitText}
        {loading && <LoadingDots className={classes.absolute}/>}
      </NextButton>
    </Grid>
  );
};

export default SubmitCancelButtons;
