import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Divider } from '@mui/material';

import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { useDomain } from '../../hooks/useDomain';
import { ROUTES } from '../../types/routes.enum';
import { LoadingSpinner } from '../ui-components/loading-spinner.component';
import { getTheme } from '../../stores/selectors/theme.selector';
import { notificationStyle } from './notificationStyle';
import CustomizedSwitches from '../ui-components/toggle.component';
import { useFetchNotificationQuery, useUpdateNotificationMutation } from '../../services/notification.api';

export const NotificationComponent = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { domain } = useDomain();
  const classes = notificationStyle();

  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const { data: notificationData, isLoading: isNotificationDataLoading } =
    useFetchNotificationQuery(undefined);
  
  const [ triggerUpdateNotification, {data: updatedNotificationData} ] = useUpdateNotificationMutation();  

  const [upcomingIdleFee, setUpcomingIdleFee] = useState(notificationData?.upcomingIdleFee.enabled);
  const [chargingStoppedFeesCont, setChargingStoppedFeesCont] = useState(notificationData?.feeActiveAfterDoneCharging.enabled);

  useEffect(() => {
    if (isNotificationDataLoading) {
      LoadingSpinner.show();
    } else {
      LoadingSpinner.hide();
    }
  }, [isNotificationDataLoading]);

  useEffect(() => {
    if (notificationData) {      
      setUpcomingIdleFee(notificationData?.upcomingIdleFee.enabled);
      setChargingStoppedFeesCont(notificationData?.feeActiveAfterDoneCharging.enabled);
    }
  }, [notificationData]);

  const backAction = () => {
    const route = `/app/${domain}/${ROUTES.ACCOUNT}${
      chargerName ? `?charger=${chargerName}` : ''
    }`;
    navigate(route);
  };



  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('notification_title')}
        onBackArrowClicked={backAction}
      />
      {!isNotificationDataLoading && (
        <Box
          component='div'
          style={{
            padding: '16px 16px 40px 16px',
          }}
        >
          <Box>
            <Grid container style={{padding: '0px 0px 4px 0px', marginBottom: '8px'}}>
              <Typography className={classes.sessionLabel}>{i18n.t('session_label')}</Typography>
            </Grid>
            <Grid container className={classes.containerWrapper1}>
              <Grid container style={{width: '261px'}}>
                <Typography className={classes.labelM}>{i18n.t('upcoming_idle_fees')}</Typography>
                <Typography className={classes.labelB2}>{i18n.t('notify_message1')}</Typography>
              </Grid>
              <Grid container className={classes.containerClass}>
                <CustomizedSwitches 
                checked={upcomingIdleFee}
                handleChange={(event) => {
                  setUpcomingIdleFee(event.target.checked);
                  triggerUpdateNotification({
                    "upcomingIdleFee": {
                      "enabled": event.target.checked
                    },
                    "feeActiveAfterDoneCharging": {
                        "enabled": chargingStoppedFeesCont
                    }
                  });
                }} />
              </Grid>
            </Grid>
            <Divider style={{marginBottom: '8px'}}/>
            <Grid container className={classes.containerWrapper}>
              <Grid container style={{width: '261px'}}>
                <Typography className={classes.labelM}>{i18n.t('charging_stopped_label')}</Typography>
                <Typography className={classes.labelB2}>{i18n.t('notify_message2')}</Typography>
              </Grid>
              <Grid container className={classes.containerClass}>
                <CustomizedSwitches 
                checked={chargingStoppedFeesCont}
                handleChange={(event) => {
                  setChargingStoppedFeesCont(event.target.checked);
                  triggerUpdateNotification({
                    "upcomingIdleFee": {
                      "enabled": upcomingIdleFee
                    },
                    "feeActiveAfterDoneCharging": {
                        "enabled": event.target.checked
                    }
                  });
                }}/>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </ViewWrapper>
  );
};
